<script lang="ts" setup>
import BaseDialog from '@/components/dialogs/BaseDialog.vue'
import SkrCheckbox from '@/components/SkrCheckbox.vue'

withDefaults(
  defineProps<{
    isDeleteEnabled: boolean
    loading: boolean
    messageMaxLength?: number
  }>(),
  {
    messageMaxLength: 1000,
  }
)

const emit = defineEmits<{
  (event: 'close'): void
  (event: 'action', data: Dialogs['DialogDeclineSR']['contextData']): void
}>()

const { t } = useI18n()

const data = reactive({
  message: '',
  shouldDeleteDocument: false,
})
</script>

<template>
  <base-dialog data-cy="decline_dialog" max-width="510" :title="t('dialog.decline_document.title')">
    <div class="pb-4">
      {{ t('dialog.decline_document.information') }}
    </div>
    <div class="mb-2">
      <strong>{{ t('dialog.decline_document.message_label') }}</strong>
    </div>
    <v-textarea
      v-model="data.message"
      auto-grow
      counter
      :maxlength="messageMaxLength"
      clearable
      clear-icon="custom:clear"
      :label="t('dialog.decline_document.message_placeholder')"
      @blur="data.message = data.message.trim()"
    />
    <template #actions>
      <template v-if="isDeleteEnabled">
        <skr-checkbox
          v-model="data.shouldDeleteDocument"
          class="ml-8"
          data-cy="delete_checkbox"
          :label="t('global.delete_document_form_account')"
        />
        <v-divider class="mt-6" />
      </template>
      <v-card-actions>
        <v-btn size="x-large" color="info" variant="outlined" @click="emit('close')">
          {{ t('global.no_thanks') }}
        </v-btn>
        <v-spacer />
        <v-btn
          size="x-large"
          color="error"
          data-cy="confirmDeclineButton"
          :disabled="loading"
          :loading="loading"
          variant="elevated"
          @click="$emit('action', data)"
        >
          {{ t('global.decline.decline') }}
        </v-btn>
      </v-card-actions>
    </template>
  </base-dialog>
</template>
